<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Base Manufaktur</h2>
      </div>
      <!-- <div>
        <a-button @click.prevent="" type="danger">
          <LeftSquareOutlined />
          Batal</a-button
        >
      </div> -->
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <a-button @click="changeEdittable">
            <span v-if="!editable">
              <FormOutlined class="mr-2" />Edit
            </span>
            <span v-else>
              <EyeOutlined class="mr-2" />View
            </span>
          </a-button>
        </a-col>
        <a-col :span="8">
          <div class="mb-5">
            <div>
              <h1 class="text-title">Nama Manufaktur</h1>
            </div>
            <div>
              <a-row :gutter="[24, 12]">
                <a-col :sm="{ span: 24 }">
                  <a-input placeholder="Masukkan Nama Manufaktur" v-model:value="dataPayload.name"
                    :disabled="!editable"></a-input>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="mb-5">
            <div>
              <h1 class="text-title">Tag Manufaktur</h1>
            </div>
            <div>
              <a-row :gutter="[24, 12]">
                <a-col :sm="{ span: 24 }">
                  <a-input placeholder="Masukkan Tag Manufaktur" v-model:value="dataPayload.tag"
                    :disabled="!editable"></a-input>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <a-button :disabled="!editable" @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'
import {
  getCustomers,
  createLocation,
  getLocations,
  getLocationsById,
  editLocationsById,
} from '../../../services/axios/modc/customer'
import { editManufacturesById, getManufacturesById } from '../../../services/axios/modc/manufacture'

export default {
  components: { LeftOutlined, FormOutlined, EyeOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()
    const assets = ref()
    const manufactures = ref()
    const loading = ref(false)
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const selectedTypeofFailure = ref()
    const selectedTypeofSolving = ref()
    const selectedReparation = ref()
    const editable = ref(false)
    const troubleshootDetailData = ref([])
    const dataCustomers = ref([])
    // const editable = ref(false)

    onBeforeMount(() => {
      fetchData()
    })
    const fetchData = async () => {
      const data = await getCustomers()
      dataCustomers.value = data.customer

      const currentData = await getManufacturesById(route.params.id)
      dataPayload.value = {
        name: currentData.manufacturers.name,
        tag: currentData.manufacturers.tag,
      }
      console.log(currentData, '<<<')
    }
    const dataPayload = ref({
      name: null,
      tag: null,
    })

    const changeEdittable = () => {
      editable.value = !editable.value
    }
    const handleSubmit = async () => {
      const data = await editManufacturesById(route.params.id, dataPayload.value)
      if (data) {
        notification.success({
          message: 'Berhasil Submit',
          description: 'Data Manufacturer Berhasil Diubah',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }

      // setTimeout(() => {
      router.push({ name: 'Base Manufaktur' })
      // }, 1000)
    }
    return {
      // detail troubleshoot
      facility,
      catFacility,
      troubleshootDetailData,
      // Last Section

      selectedReparation,
      selectedTypeofFailure,
      selectedTypeofSolving,
      // editable,
      dataCustomers,
      dataPayload,
      editable,

      // getCurrentDate,
      changeEdittable,

      handleSubmit,
      // dataSource,
    }
  },
}
</script>
<style lang="scss">
@import '@/views/styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}</style>
